<template>
  <v-card class="mx-auto" color="white" :loading="loading > 0">
    <toolbar
      :path="path"
      :storages="storagesArray"
      :storage="activeStorage"
      v-on:storage-changed="storageChanged"
      v-on:path-changed="pathChanged"
      v-on:add-files="addUploadingFiles"
      v-on:folder-created="refreshPending = true"
    ></toolbar>
    <v-row no-gutters>
      <v-col v-if="tree && $vuetify.breakpoint.smAndUp" sm="auto">
        <tree
          :path="path"
          :storage="activeStorage"
          :icons="icons"
          :refreshPending="refreshPending"
          v-on:path-changed="pathChanged"
          v-on:loading="loadingChanged"
          v-on:readFolder="readFolder"
          v-on:refreshed="refreshPending = false"
        ></tree>
      </v-col>
      <v-divider v-if="tree" vertical></v-divider>
      <v-col>
        <list
          ref="listdiscover"
          :path="path"
          :storage="activeStorage"
          :icons="icons"
          :refreshPending="refreshPending"
          v-on:path-changed="pathChanged"
          v-on:loading="loadingChanged"
          v-on:refreshed="refreshPending = false"
          v-on:file-deleted="refreshPending = true"
        ></list>
      </v-col>
    </v-row>
    <upload
      v-if="uploadingFiles !== false"
      :path="path"
      :storage="activeStorage"
      :files="uploadingFiles"
      :icons="icons"
      :maxUploadFilesCount="maxUploadFilesCount"
      :maxUploadFileSize="maxUploadFileSize"
      v-on:add-files="addUploadingFiles"
      v-on:remove-file="removeUploadingFile"
      v-on:clear-files="uploadingFiles = []"
      v-on:cancel="uploadingFiles = false"
      v-on:uploaded="uploaded"
    ></upload>
  </v-card>
</template>

<script>
import axios from 'axios';

import Toolbar from './Toolbar.vue';
import Tree from './Tree.vue';
import List from './List.vue';
import Upload from './Upload.vue';

const availableStorages = [
  {
    name: 'GH',
    code: 'local',
    icon: 'mdi-folder-multiple-outline'
  }
  // {
  //   name: 'Cloud',
  //   code: 'Cloud',
  //   icon: 'mdi-dropbox'
  // }
];

const endpoints = {
  list: { url: '/storage/{storage}/list?path={path}', method: 'get' },
  upload: { url: '/storage/{storage}/upload?path={path}', method: 'post' },
  mkdir: { url: '/storage/{storage}/mkdir?path={path}', method: 'post' },
  delete: { url: '/storage/{storage}/delete?path={path}', method: 'post' }
};

const fileIcons = {
  zip: {
    icon: 'mdi-folder-zip-outline',
    color: '#FECA28'
  },
  rar: {
    icon: 'mdi-folder-zip-outline',
    color: '#EF5350'
  },
  htm: {
    icon: 'mdi-language-html5',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  html: {
    icon: 'mdi-language-html5',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  js: {
    icon: 'mdi-nodejs',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  json: {
    icon: 'mdi-json',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  md: {
    icon: 'mdi-markdown',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  pdf: {
    icon: 'mdi-file-pdf',
    color: '#D81317'
  },
  png: {
    icon: 'mdi-file-image',
    color: '#8056C4'
  },
  jpg: {
    icon: 'mdi-file-image',
    color: '#66BA6C'
  },
  jpeg: {
    icon: 'mdi-file-image',
    color: '#66BA6C'
  },
  mp4: {
    icon: 'mdi-filmstrip',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  mkv: {
    icon: 'mdi-filmstrip',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  avi: {
    icon: 'mdi-filmstrip',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  wmv: {
    icon: 'mdi-filmstrip',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  mov: {
    icon: 'mdi-filmstrip',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  txt: {
    icon: 'mdi-file-document-outline',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  other: {
    icon: 'mdi-file-outline',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  doc: {
    icon: 'mdi-file-word',
    color: '#295294'
  },
  docx: {
    icon: 'mdi-file-word',
    color: '#295294'
  },
  ppt: {
    icon: 'mdi-file-powerpoint',
    color: '#CB4424'
  },
  pptx: {
    icon: 'mdi-file-powerpoint',
    color: '#CB4424'
  },
  xls: {
    icon: 'mdi-file-excel',
    color: '#007C00'
  },
  xlsx: {
    icon: 'mdi-file-excel',
    color: '#007C00'
  }
};

export default {
  components: {
    Toolbar,
    Tree,
    List,
    Upload
  },
  model: {
    prop: 'path',
    event: 'change'
  },
  props: {
    storages: {
      type: String,
      default: () => availableStorages.map((item) => item.code).join(',')
    },
    storage: { type: String, default: 'local' },
    tree: { type: Boolean, default: true },
    icons: { type: Object, default: () => fileIcons },
    endpoints: { type: Object, default: () => endpoints },
    axios: { type: Function },
    axiosConfig: { type: Object, default: () => ({ baseURL: 'http://localhost:8000' }) },
    maxUploadFilesCount: { type: Number, default: 0 },
    maxUploadFileSize: { type: Number, default: 0 }
  },
  data() {
    return {
      loading: 0,
      path: '',
      activeStorage: null,
      uploadingFiles: false, // or an Array of files
      refreshPending: false,
      axiosInstance: null
    };
  },
  computed: {
    storagesArray() {
      let storageCodes = this.storages.split(','),
        result = [];
      storageCodes.forEach((code) => {
        result.push(availableStorages.find((item) => item.code == code));
      });
      return result;
    }
  },
  methods: {
    loadingChanged(loading) {
      if (loading) {
        this.loading++;
      } else if (this.loading > 0) {
        this.loading--;
      }
    },
    storageChanged(storage) {
      this.activeStorage = storage;
    },
    addUploadingFiles(files) {
      files = Array.from(files);

      if (this.maxUploadFileSize) {
        files = files.filter((file) => file.size <= this.maxUploadFileSize);
      }

      if (this.uploadingFiles === false) {
        this.uploadingFiles = [];
      }

      if (this.maxUploadFilesCount && this.uploadingFiles.length + files.length > this.maxUploadFilesCount) {
        files = files.slice(0, this.maxUploadFilesCount - this.uploadingFiles.length);
      }

      this.uploadingFiles.push(...files);
    },
    removeUploadingFile(index) {
      this.uploadingFiles.splice(index, 1);
    },
    uploaded() {
      this.uploadingFiles = false;
      this.refreshPending = true;
    },
    pathChanged(path) {
      this.path = path;
      this.$emit('change', path);
    },
    readFolder(item) {
      // console.log('emit', item);
      this.$refs.listdiscover.loadDiscover(item);
    }
  },
  created() {
    this.activeStorage = this.storage;
    this.axiosInstance = this.axios || axios.create(this.axiosConfig);
  },
  mounted() {
    if (!this.path && !(this.tree && this.$vuetify.breakpoint.smAndUp)) {
      this.pathChanged('/');
    }
  }
};
</script>

<style lang="scss" scoped></style>
