<template>
  <v-card flat tile width="400" min-height="380" class="d-flex flex-column folders-tree-card">
    <div class="grow scroll-x">
      <v-treeview
        :open="open"
        :active="active"
        :items="treeBase"
        :search="filter"
        :load-children="readFolder"
        v-on:update:active="activeChanged"
        item-key="path"
        item-text="basename"
        dense
        activatable
        transition
        class="folders-tree tree-file-browser"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon color="primary" v-if="item.type === 'dir'">{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
          <v-icon
            :color="
              icons[item.extension.toLowerCase()] ? icons[item.extension.toLowerCase()].color : icons['other'].color
            "
            v-else
            >{{
              icons[item.extension.toLowerCase()] ? icons[item.extension.toLowerCase()].icon : icons['other'].icon
            }}</v-icon
          >
        </template>
        <template v-slot:label="{ item }">
          {{ item.basename }}
          <!--          <v-btn icon v-if="item.type === 'dir'" @click.stop="readFolder(item)" class="ml-1">-->
          <!--            <v-icon class="pa-0 mdi-18px" color="grey lighten-1">mdi-refresh</v-icon>-->
          <!--          </v-btn>-->
        </template>
      </v-treeview>
    </div>
    <v-divider></v-divider>
    <v-toolbar dense flat class="shrink">
      <v-text-field
        solo
        flat
        hide-details
        label="Filtrar"
        v-model="filter"
        prepend-inner-icon="mdi-filter-outline"
        class="ml-n3"
      ></v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="init" v-on="on">
            <v-icon>mdi-collapse-all-outline</v-icon>
          </v-btn>
        </template>
        <span>Contraer todo</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="openallnodes" v-on="on">
            <v-icon>mdi-expand-all-outline</v-icon>
          </v-btn>
        </template>
        <span>Expandir todo</span>
      </v-tooltip>
    </v-toolbar>
  </v-card>
</template>

<script>
import fire from '@/fire';
import { decryptData } from '@/components4x/utils/utils4x';

export default {
  props: {
    icons: Object,
    storage: String,
    path: String,
    endpoints: Object,
    axios: Function,
    refreshPending: Boolean
  },
  data() {
    return {
      open: [],
      active: [],
      items: [],
      filter: '',
      treeBase: [],
      nodes: []
    };
  },
  methods: {
    init() {
      this.open = [];
      if (this.path !== '') {
        this.$emit('path-changed', '');
      }
    },
    openallnodes() {
      this.nodes.forEach((el) => {
        if (el.type === 'dir') this.open.push(el.path);
      });
    },
    // eslint-disable-next-line no-unused-vars
    async readFolder(item) {
      // console.log(item);
    },
    activeChanged(active) {
      this.active = active;
      let path = '';
      if (active.length) {
        const fil = this.nodes.filter((el) => el.path === active[0]);
        this.$emit('readFolder', fil[0]);
        path = active[0];
      }
      if (this.path !== path) {
        this.$emit('path-changed', path);
      }
    },
    findItem(path) {
      let stack = [];
      stack.push(this.items[0]);
      while (stack.length > 0) {
        let node = stack.pop();
        if (node.path == path) {
          return node;
        } else if (node.children && node.children.length) {
          for (let i = 0; i < node.children.length; i++) {
            stack.push(node.children[i]);
          }
        }
      }
      return null;
    },
    resetTaskTree(tasks) {
      const tasksById = {};
      for (let i = 0, len = tasks.length; i < len; i++) {
        let current = tasks[i];
        current.arrchildren = [];
        if (current.type === 'dir') current.children = [];
        current.allChildren = [];
        current.allChildren_id = [];
        current.parent = null;
        current.parents = [];
        current.parents_id = [];
        current.parentNodes = [];
        current.childrenNodes = [];
        tasksById[current.id] = current;
      }
      return tasksById;
    },
    recursiveGenerateTree(node, nodes) {
      // calc(100vh - 64px - 64px - 64px - 64px - 64px - 24px )
      for (const nodesObj of nodes) {
        let current = nodesObj;

        //console.log('<>', current, current.parentId, node);
        if (current.parentId === node.id) {
          if (node.parents.length) {
            node.parents.forEach((parent) => current.parents.push(parent));
            node.parents_id.forEach((parent) => current.parents_id.push(parent));
            node.parentNodes.forEach((parent) => current.parentNodes.push({ ...parent }));
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!node.propertyIsEnumerable('__root')) {
            current.parents.push(node.id);
            current.parents_id.push(node._id);
            current.parentNodes.push({
              _id: node._id,
              private: node.private
            });
            current.parent = node.id;
          } else {
            current.parents = [];
            current.parents_id = [];
            current.parentNodes = [];
            current.parent = null;
          }
          current = this.recursiveGenerateTree(current, nodes);
          node.allChildren.push(current.id);
          node.allChildren_id.push(current._id);
          node.childrenNodes.push({
            _id: current._id,
            private: current.private
          });

          node.arrchildren.push(current.id);
          current.allChildren.forEach((childId) => node.allChildren.push(childId));
          current.allChildren_id.forEach((childId) => node.allChildren_id.push(childId));
          current.childrenNodes.forEach((child) => node.childrenNodes.push({ ...child }));
        }
      }

      return node;
    },
    recursiveCreateTree(node, nodes) {
      for (const nodeObj of nodes) {
        let current = nodeObj;

        if (current.parentId === node.id) {
          console.log(current, current.parentId, node.id);
          if (node.arrchildren.length > 0) {
            current = this.recursiveCreateTree(current, nodes);
            current.used = true;
            node.children.push(current);
          }
        }
      }
      return node;
    }
  },
  watch: {
    storage() {
      this.init();
    },
    path() {
      this.active = [this.path];
      if (!this.open.includes(this.path)) {
        this.open.push(this.path);
      }
    },
    async refreshPending() {
      // if (this.refreshPending) {
      //   let item = this.findItem(this.path);
      //   await this.readFolder(item);
      //   this.$emit('refreshed');
      // }
    }
  },
  async created() {
    this.init();
    this.$emit('loading', true);

    const refFirestore = fire.firestore().collection('documentations');

    // eslint-disable-next-line no-unused-vars
    const observer = refFirestore.onSnapshot(
      // eslint-disable-next-line no-unused-vars
      async (docSnapshot) => {
        this.$emit('loading', false);
        this.$emit('loading', true);
        this.nodes = [];

        const queryPublic = await refFirestore.where('private', '==', false).get();

        const idresponsable = localStorage.getItem('lrd5qwlr674');

        if (idresponsable === null) return;

        const decryptId = decryptData(idresponsable);

        const isAdmin = ['LILUZ', 'MPHENRY', 'RVRAUL', 'SVLIZBETH'].some((el) => el === decryptId);

        let queryPrivate = [];

        if (isAdmin) {
          queryPrivate = await refFirestore.where('private', '==', true).get();
        } else {
          queryPrivate = await refFirestore
            .where('private', '==', true)
            .where('access', 'array-contains', decryptId)
            .get();
        }

        if (!queryPublic.empty) {
          queryPublic.forEach((doc) => {
            this.nodes.push({
              _id: doc.id,
              id: doc.data().id,
              type: doc.data().type,
              path: doc.data().path,
              name: doc.data().name,
              createdBy: doc.data().createdBy,
              idCreatedBy: doc.data().idCreatedBy,
              createdAt: doc.data()?.createdAt ?? '',
              updateAt: doc.data()?.updateAt ?? '',
              basename: doc.data().basename,
              size: doc.data()?.size ?? null,
              extension: doc.data()?.extension ?? '',
              parentId: doc.data()?.parentId ?? null,
              downloadURL: doc.data()?.downloadURL ?? null,
              private: doc.data()?.private ?? false,
              access: doc.data()?.access ?? [],
              usage: false
            });
          });
        }

        if (!queryPrivate.empty) {
          queryPrivate.forEach((doc) => {
            this.nodes.push({
              _id: doc.id,
              id: doc.data().id,
              type: doc.data().type,
              path: doc.data().path,
              name: doc.data().name,
              createdBy: doc.data().createdBy,
              idCreatedBy: doc.data().idCreatedBy,
              createdAt: doc.data()?.createdAt ?? '',
              updateAt: doc.data()?.updateAt ?? '',
              basename: doc.data().basename,
              size: doc.data()?.size ?? null,
              extension: doc.data()?.extension ?? '',
              parentId: doc.data()?.parentId ?? null,
              downloadURL: doc.data()?.downloadURL ?? null,
              private: doc.data()?.private ?? false,
              access: doc.data()?.access ?? null,
              usage: false
            });
          });
        }

        const inode = this.nodes.findIndex((el) => el.id === 0);

        this.resetTaskTree(this.nodes);

        this.recursiveGenerateTree(this.nodes[inode], this.nodes);

        this.recursiveCreateTree(this.nodes[inode], this.nodes);

        this.treeBase = [this.nodes[inode]];

        this.$emit('loading', false);
        this.activeChanged(this.active);
      },
      (err) => {
        console.error(`Error: ${err}`);
      }
    );
  }
};
</script>

<style lang="scss" scoped>
.folders-tree-card {
  height: 100%;

  .scroll-x {
    overflow-x: auto;
  }

  ::v-deep .folders-tree {
    width: fit-content;
    min-width: 250px;

    .v-treeview-node {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
  }
}
</style>
