import firebase from 'firebase';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyC3JAF0U7SG8pbg60Egqn8BgTIe2WI7OUA',
  authDomain: 'fir-gestion.firebaseapp.com',
  projectId: 'fir-gestion',
  storageBucket: 'fir-gestion.appspot.com',
  messagingSenderId: '757935947044',
  appId: '1:757935947044:web:cfa346c445667cb209c7b1'
};

const fire = firebase.initializeApp(config);
export default fire;
