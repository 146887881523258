<template>
  <v-card flat tile min-height="380" class="d-flex flex-column">
    <confirm ref="confirm"></confirm>
    <v-card-text v-if="!path" class="grow d-flex justify-center align-center grey--text"
      >Seleccione un folder o un archivo</v-card-text
    >
    <v-card-text v-else-if="isFile" class="grow d-flex justify-center align-center">Archivo: {{ path }}</v-card-text>
    <v-card-text v-else-if="dirs.length || files.length" class="grow">
      <v-list subheader v-if="dirs.length">
        <v-subheader>Folders</v-subheader>
        <v-list-item v-for="item in dirs" :key="item.basename" @click="changePath(item.path)" class="pl-0">
          <v-list-item-avatar class="ma-0">
            <v-icon color="primary">mdi-folder</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="py-2">
            <v-list-item-title v-text="item.basename"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="d-block">
            <v-tooltip v-if="item.private" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop>
                  <v-icon v-if="item.private" color="green darken-4">mdi-lock</v-icon>
                </v-btn>
              </template>

              <span>{{
                `Este ${item.type === 'dir' ? 'folder' : 'archivo'} es ${item.private ? 'privado' : 'público'}`
              }}</span>
            </v-tooltip>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-auth-acl="'gh-doc-expl-changestatus'" @click="changeStatusItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">{{ item.private ? 'mdi-lock-open' : 'mdi-lock' }}</v-icon>
                  {{ `Cambiar a ${item.private ? 'público' : 'privado'}` }}
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-share'" @click="shareItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-share-variant</v-icon> Compartir
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-edit'" @click="renameItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-pencil-outline</v-icon> Renombrar
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-delete'" @click="deleteItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-delete-outline</v-icon> Eliminar
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-information'" @click="swtEventAppendPrivate">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-information</v-icon> Información
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="dirs.length && files.length"></v-divider>
      <v-list subheader v-if="files.length">
        <v-subheader>Archivos</v-subheader>
        <v-list-item v-for="item in files" :key="item.basename" @click="changePath(item.path)" class="pl-0">
          <v-list-item-avatar class="ma-0">
            <v-icon
              :color="
                icons[item.extension.toLowerCase()] ? icons[item.extension.toLowerCase()].color : icons['other'].color
              "
              >{{
                icons[item.extension.toLowerCase()] ? icons[item.extension.toLowerCase()].icon : icons['other'].icon
              }}</v-icon
            >
          </v-list-item-avatar>

          <v-list-item-content class="py-2">
            <v-list-item-title style="white-space: pre-line" v-text="item.basename"></v-list-item-title>
            <v-list-item-subtitle>{{ formatBytes(item.size) }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="d-block">
            <v-tooltip v-if="item.private" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop>
                  <v-icon v-if="item.private" color="green darken-4">mdi-lock</v-icon>
                </v-btn>
              </template>

              <span>{{
                `Este ${item.type === 'dir' ? 'folder' : 'archivo'} es ${item.private ? 'privado' : 'público'}`
              }}</span>
            </v-tooltip>

            <v-btn
              v-auth-acl="'gh-doc-expl-view'"
              v-if="item.downloadURL !== null && isFrame(item)"
              icon
              @click.stop="viewItem(item)"
            >
              <v-icon color="grey lighten-1">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              v-auth-acl="'gh-doc-expl-download'"
              v-if="item.downloadURL !== null"
              icon
              @click.stop="downloadItem(item)"
            >
              <v-icon color="grey lighten-1">mdi-download</v-icon>
            </v-btn>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-auth-acl="'gh-doc-expl-changestatus'" @click="changeStatusItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">{{ item.private ? 'mdi-lock-open' : 'mdi-lock' }}</v-icon>
                  {{ `Cambiar a ${item.private ? 'público' : 'privado'}` }}
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-share'" @click="shareItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-share-variant</v-icon> Compartir
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-edit'" @click="renameItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-pencil-outline</v-icon> Renombrar
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-delete'" @click="deleteItem(item)">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-delete-outline</v-icon> Eliminar
                </v-list-item>
                <v-list-item v-auth-acl="'gh-doc-expl-information'" @click="swtEventAppendPrivate">
                  <v-icon color="grey lighten-1" class="mr-2">mdi-information</v-icon> Información
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else-if="filter" class="grow d-flex justify-center align-center grey--text py-5"
      >No se encontraron folders o archivos</v-card-text
    >
    <v-card-text v-else class="grow d-flex justify-center align-center grey--text py-5"
      >El folder está vacío</v-card-text
    >
    <v-divider v-if="path"></v-divider>
    <v-toolbar v-if="false && path && isFile" dense flat class="shrink">
      <v-btn icon>
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar v-if="path && isDir" dense flat class="shrink">
      <v-text-field
        solo
        flat
        hide-details
        label="Filtrar"
        v-model="filter"
        prepend-inner-icon="mdi-filter-outline"
        class="ml-n3"
      ></v-text-field>
      <v-btn icon v-if="false">
        <v-icon>mdi-eye-settings-outline</v-icon>
      </v-btn>
      <v-btn icon @click="load">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-dialog
      v-auth-acl="'gh-doc-expl-view'"
      v-model="dialogViewItem"
      scrollable
      persistent
      @click:outside="closeViewItem"
      @keydown.esc="closeViewItem"
    >
      <v-card>
        <v-card-text>
          <iframe class="container-frame" :src="srcViewItem"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-auth-acl="'gh-doc-expl-share'"
      v-model="dialogShareItem"
      scrollable
      persistent
      @click:outside="closeShareItem"
      @keydown.esc="closeShareItem"
      width="800px"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <div>
            <v-btn color="primary" class="white--text mr-2" fab small>
              <v-icon dark>mdi-account-multiple-plus</v-icon>
            </v-btn>
            Compartir "{{ nameHeader }}"
          </div>
          <div>
            <v-icon color="primary">{{ swtPrivate ? 'mdi-lock' : 'mdi-lock-open-outline' }}</v-icon>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="contacts mt-5">
          <div class="d-flex justify-end">
            <v-switch v-model="swtArea" color="primary" :label="`Añadir toda el área de '${areaActual}'`"></v-switch>
          </div>
          <v-autocomplete
            v-model="responsable"
            :items="listResponsables"
            :disabled="loadingDelete"
            chips
            color="primary"
            label="Agregar personas"
            item-text="responsable"
            item-value="idresponsable"
            hide-no-data
            hide-selected
            multiple
            return-object
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeItemAutocomplete(data.item)"
              >
                {{ data.item.responsable }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <!-- <v-list-item-avatar>-->
                <!--   <img :src="data.item.avatar" />-->
                <!-- </v-list-item-avatar>-->
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.responsable"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.subarea"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <div v-if="responsable.length > 0">
            <v-container class="px-0" fluid>
              <v-checkbox v-model="sendNotifications" label="Enviar notificaciones"></v-checkbox>
            </v-container>
          </div>
          <div v-else v-bar>
            <v-data-table
              :headers="listSharedHeaders"
              :items="listShared"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              class="elevation-1 overflow-hidden"
            >
              <template v-slot:item.responsable="{ item }">
                <div class="d-flex align-center">
                  <div class="mr-2 my-2">
                    <v-img width="42" height="42" :src="require('@/assets/images/user-default.png')" />
                  </div>
                  <span>{{ item.responsable }}</span>
                </div>
              </template>

              <template v-slot:item.acciones="{ item }">
                <v-chip v-if="item.propietario" class="" color="primary" text-color="white" small>
                  {{ item.propietario ? 'propietario' : 'invitado' }}
                </v-chip>

                <v-btn
                  v-else
                  :loading="item.loading"
                  :disabled="item.loading"
                  :elevation="-1"
                  color="transparent"
                  small
                  fab
                  @click="removeShareItem(item)"
                >
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <!--            <v-list two-line class="contact-list">-->
            <!--              <v-list-item-group>-->
            <!--                <template v-for="(item, index) in listShared">-->
            <!--                  <v-list-item :key="`${index}-listcontact`">-->
            <!--                    <v-list-item-avatar>-->
            <!--                      <v-img :src="require('@/assets/images/user-default.png')" />-->
            <!--                    </v-list-item-avatar>-->
            <!--                    <v-list-item-content class="ml-2">-->
            <!--                      <v-list-item-title>{{ item.responsable }}</v-list-item-title>-->
            <!--                    </v-list-item-content>-->
            <!--                    <v-list-item-content>-->
            <!--                      <v-list-item-title>{{ item.subarea }}</v-list-item-title>-->
            <!--                    </v-list-item-content>-->
            <!--                    <v-list-item-action v-if="item.propietario" class="d-flex align-center">-->
            <!--                      <v-chip class="" color="primary" text-color="white" small>-->
            <!--                        {{ item.propietario ? 'propietario' : 'invitado' }}-->
            <!--                      </v-chip>-->
            <!--                    </v-list-item-action>-->
            <!--                    <v-list-item-action v-if="!item.propietario" @click.stop class="d-flex align-center">-->
            <!--                      <v-icon left color="error">mdi-delete</v-icon>-->
            <!--                    </v-list-item-action>-->
            <!--                  </v-list-item>-->
            <!--                  <v-divider v-if="index + 1 < listShared.length" :key="index"></v-divider>-->
            <!--                </template>-->
            <!--              </v-list-item-group>-->
            <!--            </v-list>-->
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" @click="closeShareItem" text>Cerrar</v-btn>
          <v-btn
            v-if="responsable.length > 0"
            @click="aceptShareItem"
            :loading="loadingAceptShare"
            :disabled="loadingAceptShare"
            color="primary"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import { formatBytes } from './util';
import Confirm from './Confirm.vue';
import fire from '@/fire';
import { notificateShareFolderAndFile } from '@/services/notificationService';
// eslint-disable-next-line no-unused-vars
import Swal from 'sweetalert2';
import DocumentacionService from '@/giplan/documentacion/dashboard/services/DocumentacionService';
// eslint-disable-next-line no-unused-vars
import firebase from 'firebase';
import { decryptAES, decryptData } from '@/components4x/utils/utils4x';

export default {
  props: {
    icons: Object,
    storage: String,
    path: String,
    endpoints: Object,
    axios: Function,
    refreshPending: Boolean
  },
  components: {
    Confirm
  },
  data() {
    return {
      documentacionService: null,
      showLoading: false,
      areaActual: null,
      dialogShareItem: false,
      dialogViewItem: false,
      swtArea: false,
      swtPrivate: false,
      loadingDelete: false,
      loadingAceptShare: false,
      srcViewItem: '',
      items: [],
      filter: '',
      listSharedHeaders: [
        {
          text: 'responsable',
          value: 'responsable'
        },
        {
          text: 'subarea',
          value: 'subarea'
        },
        {
          text: 'acciones',
          value: 'acciones',
          align: 'center'
        }
      ],
      listShared: [
        {
          idsubarea: 'element.idsubarea',
          subarea: 'element.subarea',
          idresponsable: 'element.idresponsable',
          responsable: 'element.responsable',
          estado: 'propietario'
        }
      ],
      nameHeader: '',
      typeDoc: '',
      sendNotifications: false,
      responsable: [],
      listResponsables: [],
      syncResponsables: [],
      parentAndChild: [],
      currentAndChildren: []
    };
  },
  computed: {
    dirs() {
      return this.items.filter((item) => item.type === 'dir' && item.basename.includes(this.filter));
    },
    files() {
      return this.items.filter((item) => item.type === 'file' && item.basename.includes(this.filter));
    },
    isDir() {
      return this.path[this.path.length - 1] === '/';
    },
    isFile() {
      return !this.isDir;
    }
  },
  methods: {
    formatBytes,
    changePath(path) {
      this.$emit('path-changed', path);
    },
    async load() {
      this.$emit('loading', true);
      this.$emit('loading', false);
    },
    isFrame(item) {
      return (
        item.extension === 'doc' ||
        item.extension === 'docx' ||
        item.extension === 'ppt' ||
        item.extension === 'pptx' ||
        item.extension === 'xls' ||
        item.extension === 'xlsx' ||
        item.extension === 'pdf'
      );
    },
    async loadDiscover(item) {
      const el = item?.children ?? [];

      this.items = el;
    },
    async deleteItem(item) {
      let confirmed = await this.$refs.confirm.open(
        'Papelera',
        `Estás seguro de eliminar el ${item.type === 'dir' ? 'folder' : 'archivo'}?<br><b>${item.basename}</b></br>`
      );

      if (confirmed) {
        this.$emit('loading', true);
        await fire
          .firestore()
          .collection('documentations')
          .doc(item._id)
          .delete();
        this.$emit('file-deleted');
        this.$emit('loading', false);
      }
    },
    downloadItem(item) {
      //window.location.href = item.downloadURL;
      window.open(item.downloadURL, '_blank');
    },
    viewItem(item) {
      let urlbase = '';

      if (
        item.extension === 'doc' ||
        item.extension === 'docx' ||
        item.extension === 'ppt' ||
        item.extension === 'pptx' ||
        item.extension === 'xls' ||
        item.extension === 'xlsx'
      ) {
        urlbase = 'https://view.officeapps.live.com/op/embed.aspx?src=';
      }

      this.srcViewItem = `${urlbase}${item.downloadURL}`;

      this.dialogViewItem = true;
    },
    closeViewItem() {
      ///srcViewItem
      this.dialogViewItem = false;
      this.srcViewItem = '';
    },
    async renameItem(item) {
      // eslint-disable-next-line no-unused-vars
      const result = await Swal.fire({
        title: 'Ingrese el nuevo nombre de la carpeta',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Renombrar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          return this.rename(item, value)
            .then((response) => {
              if (!response.state) {
                throw new Error('Sucedió un error al realizar la operación');
              }
              return response;
            })
            .catch((error) => {
              Swal.showValidationMessage(`${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    async rename(item, value) {
      if (value.length <= 3) {
        throw new Error('El nombre debe contener más de 3 caracteres');
      }

      const _id = item._id;
      const itemPath = item.path;
      const itemExt = item.extension;
      const isDir = item.type === 'dir';

      const ref = fire.firestore().collection('documentations');

      if (isDir) {
        const valuePath = `/${value}/`;

        const itemPathSlice = item.path.slice(0, -1).split('/');
        itemPathSlice.pop();
        const itemPathReplace = `${itemPathSlice.join('/')}${valuePath}`;

        const validate = await ref.where('path', '==', itemPathReplace).get();

        if (!validate.empty) {
          throw new Error('Ya existe una carpeta con ese nombre');
        }

        await ref.doc(_id).update({ name: value, basename: value });

        // const arrRes = await ref
        //   .orderBy('path')
        //   .startAt(itemPath)
        //   .get();

        const arrRes = await ref
          .orderBy('path')
          .startAt(itemPath)
          .endAt(itemPath + '\uf8ff')
          .get();

        if (arrRes.empty) return;

        const respMatch = [];

        arrRes.forEach((doc) => {
          respMatch.push({
            _id: doc.id,
            id: doc.data().id,
            path: doc.data().path
          });
        });

        respMatch.map((el) => (el.path = el.path.replace(itemPath, itemPathReplace)));

        let batch = fire.firestore().batch();
        for (const element of respMatch) {
          let refbatch = ref.doc(element._id);
          batch.update(refbatch, { path: element.path });
        }
        // eslint-disable-next-line no-unused-vars
        const resbatch = await batch.commit();

        return { state: true };
      }

      const valueFile = `/${value}.${itemExt}`;
      const itemPathFile = item.path.split('/');
      itemPathFile.pop();
      const itemPathReplace = `${itemPathFile.join('/')}${valueFile}`;

      const validate = await ref.where('path', '==', itemPathReplace).get();

      if (!validate.empty) {
        throw new Error('Ya existe un archivo con ese nombre');
      }

      await ref.doc(_id).update({ path: itemPathReplace, name: value, basename: `${value}.${itemExt}` });

      return { state: true };
    },
    async shareItem(item) {
      this.nameHeader = item.basename;
      this.typeDoc = item.type;
      this.swtPrivate = item.private;
      this.showLoading = true;
      await this.syncData();

      this.listShared = [];

      if (item.access.length > 0) {
        item.access.forEach((el) => {
          const res = this.listResponsables.filter((elem) => elem.idresponsable === el);
          if (res.length > 0) {
            this.listShared.push({
              idresponsable: res[0].idresponsable,
              responsable: res[0].responsable,
              subarea: res[0].subarea,
              loading: false,
              propietario: res[0].idresponsable === item.idCreatedBy
            });
          }
        });

        item.access.forEach((el) => {
          const i = this.listResponsables.findIndex((elem) => elem.idresponsable === el);
          if (i > -1) this.listResponsables.splice(i, 1);
        });
      }

      const refParents = item.parentNodes.filter((el) => el.private === true);
      const refChildrens = item.childrenNodes.filter((el) => el.private === true);
      const refActual = item._id;

      this.parentAndChild = [refActual];
      this.currentAndChildren = [refActual];

      if (refParents.length > 0) {
        refParents.forEach((el) => this.parentAndChild.push(el._id));
      }
      if (refChildrens.length > 0) {
        refChildrens.forEach((el) => this.parentAndChild.push(el._id));
        refChildrens.forEach((el) => this.currentAndChildren.push(el._id));
      }

      this.showLoading = false;
      this.dialogShareItem = true;
      //------------------------------------------------------------------------
    },
    closeShareItem() {
      if (this.loadingAceptShare) return;
      this.dialogShareItem = false;
      this.nameHeader = '';
      this.typeDoc = '';
      this.responsable = [];
      this.listResponsables = [];
      this.parentAndChild = [];
      this.currentAndChildren = [];
      this.swtArea = false;
      this.swtPrivate = false;
      this.sendNotifications = false;
    },
    removeItemAutocomplete(item) {
      const i = this.responsable.findIndex((el) => el.idresponsable === item.idresponsable);
      this.responsable.splice(i, 1);
    },
    async syncData() {
      this.listResponsables = [];

      const res = await this.documentacionService.get().execResource('subareasresponsable', {
        idempresa: decryptAES(localStorage.getItem('emp')),
        idsubarea: ''
      });

      if (res.length > 0) {
        res.forEach((element) => {
          this.listResponsables.push({
            idsubarea: element.idsubarea,
            subarea: element.subarea,
            idresponsable: element.idresponsable,
            responsable: element.responsable
          });
        });
      } else {
        this.listResponsables = [];
      }
    },
    async aceptShareItem() {
      this.loadingAceptShare = true;
      const ref = fire.firestore().collection('documentations');

      let batch = fire.firestore().batch();

      for (const id of this.parentAndChild) {
        const refBatch = ref.doc(id);

        for (const responsable of this.responsable) {
          batch.update(refBatch, {
            access: firebase.firestore.FieldValue.arrayUnion(responsable.idresponsable)
          });
        }
      }
      // eslint-disable-next-line no-unused-vars
      const resBatch = await batch.commit();

      if (this.sendNotifications) {
        const type = this.typeDoc === 'dir' ? 'folder' : 'archivo';
        const emisor = localStorage.getItem('nc4rhtcc976');
        const decryptNameRes = decryptData(emisor);
        for (const responsable of this.responsable) {
          await notificateShareFolderAndFile(responsable.idresponsable, decryptNameRes, type, this.nameHeader);
        }
      }

      this.loadingAceptShare = false;
      this.parentAndChild = [];
      this.currentAndChildren = [];
      this.closeShareItem();
    },
    async removeShareItem(responsable) {
      this.loadingDelete = true;

      const i = this.listShared.findIndex((el) => el.idresponsable === responsable.idresponsable);
      this.listShared[i].loading = true;

      const ref = fire.firestore().collection('documentations');
      let batch = fire.firestore().batch();

      for (const id of this.currentAndChildren) {
        const refBatch = ref.doc(id);
        batch.update(refBatch, {
          access: firebase.firestore.FieldValue.arrayRemove(responsable.idresponsable)
        });
      }
      // eslint-disable-next-line no-unused-vars
      const resBatch = await batch.commit();

      this.listShared.splice(i, 1);

      this.parentAndChild = [];
      //this.currentAndChildren = [];

      this.loadingDelete = false;
    },
    swtEventAppendPrivate($event) {
      console.log('swtEventAppendPrivate', $event);
    },
    async changeStatusItem(item) {
      const state = !item.private;
      const refParents = item.parentNodes.filter((el) => el.private === true);
      const refChildrens = item.childrenNodes;
      const arrIdParentChildren = [item._id];

      if (refParents.length > 0) {
        refParents.forEach((el) => arrIdParentChildren.push(el._id));
      }
      if (refChildrens.length > 0) {
        refChildrens.forEach((el) => arrIdParentChildren.push(el._id));
      }

      const ref = fire.firestore().collection('documentations');

      let batch = fire.firestore().batch();

      for (const id of arrIdParentChildren) {
        const refBatch = ref.doc(id);
        batch.update(refBatch, {
          private: state
        });
      }
      // eslint-disable-next-line no-unused-vars
      const resBatch = await batch.commit();
    }
  },
  watch: {
    async path() {
      //this.items = [];
      //await this.load();
    },
    async refreshPending() {
      if (this.refreshPending) {
        //await this.load();
        this.$emit('refreshed');
      }
    },
    swtArea(val) {
      if (val) {
        this.responsable = this.listResponsables.filter((el) => el.idsubarea === this.areaActual);
      } else {
        this.responsable = [];
      }
    }
  },
  created() {
    const idsubarea = localStorage.getItem('oq1rcizm635');
    this.areaActual = decryptData(idsubarea);
    this.documentacionService = this.$httpService(new DocumentacionService(), this);
  }
};
</script>

<style lang="scss" scoped>
.container-frame {
  width: 100%;
  height: calc(100vh - 212px);
  margin: 25px 0px;
}
.v-card {
  height: 100%;
}
</style>
