<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-doc-expl-read'">
      <v-col cols="12" sm="12" lg="12">
        <DocumentBrowser></DocumentBrowser>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentBrowser from '@/giplan/documentacion/dashboard/components/DocumentBrowser';

export default {
  name: 'Dashboard',

  data: () => ({
    presupuestoService: null,
    page: {
      title: 'Documentacion'
    },
    breadcrumbs: [
      {
        text: 'Documentacion',
        disabled: true
      },
      {
        text: 'Dashboard',
        disabled: true
      }
    ]
  }),
  components: {
    DocumentBrowser
  },
  methods: {}
};
</script>
