<template>
  <v-toolbar flat dense color="primary">
    <v-toolbar-items>
      <v-menu offset-y v-if="storages.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="storage-select-button mr-3" v-on="on">
            <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list class="storage-select-list">
          <v-list-item
            v-for="(item, index) in storages"
            :key="index"
            :disabled="item.code === storageObject.code"
            @click="changeStorage(item.code)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text :input-value="path === '/'" @click="changePath('/')">
        <v-icon color="white" class="mr-2">{{ storageObject.icon }}</v-icon>
        <span class="white--text">{{ storageObject.name }}</span>
      </v-btn>
      <template v-for="(segment, index) in pathSegments">
        <v-icon color="white" :key="index + '-icon'">mdi-chevron-right</v-icon>
        <v-btn
          text
          :input-value="index === pathSegments.length - 1"
          :key="index + '-btn'"
          @click="changePath(segment.path)"
          class="white--text"
          >{{ segment.name }}</v-btn
        >
      </template>
    </v-toolbar-items>
    <div class="flex-grow-1"></div>

    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-tooltip bottom v-if="pathSegments.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="goUp" v-on="on">
            <v-icon color="white">mdi-arrow-up-bold-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="pathSegments.length === 1">Subir a "GH"</span>
        <span v-else>Subir a "{{ pathSegments[pathSegments.length - 2].name }}"</span>
      </v-tooltip>
      <v-menu
        v-auth-acl="'gh-doc-expl-mkdir'"
        v-if="path && isfolder"
        v-model="newFolderPopper"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" title="Crear folder">
            <v-icon color="white">mdi-folder-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-text-field label="Nombre" v-model="newFolderName" hide-details></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn @click="newFolderPopper = false" depressed>Cancelar</v-btn>
            <v-btn color="success" :disabled="!newFolderName" depressed @click="mkdir">Crear Folder</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        v-auth-acl="'gh-doc-expl-uploadfile'"
        v-if="path && isfolder"
        icon
        @click="$refs.inputUpload.click()"
        title="Upload Files"
      >
        <v-icon color="white">mdi-plus-circle</v-icon>
        <input v-show="false" ref="inputUpload" type="file" multiple @change="addFiles" />
      </v-btn>
    </template>
  </v-toolbar>
</template>

<script>
import fire from '@/fire';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { decryptData } from '@/components4x/utils/utils4x';

export default {
  props: {
    storages: Array,
    storage: String,
    path: String
  },
  data() {
    return {
      newFolderPopper: false,
      newFolderName: ''
    };
  },
  computed: {
    isfolder() {
      return this.path[this.path.length - 1] === '/';
    },
    pathSegments() {
      let path = '/',
        isFolder = this.path[this.path.length - 1] === '/',
        segments = this.path.split('/').filter((item) => item);

      segments = segments.map((item, index) => {
        path += item + (index < segments.length - 1 || isFolder ? '/' : '');
        return {
          name: item,
          path
        };
      });

      return segments;
    },
    storageObject() {
      return this.storages.find((item) => item.code == this.storage);
    }
  },
  methods: {
    changeStorage(code) {
      if (this.storage != code) {
        this.$emit('storage-changed', code);
        this.$emit('path-changed', '');
      }
    },
    changePath(path) {
      this.$emit('path-changed', path);
    },
    goUp() {
      let segments = this.pathSegments,
        path = segments.length === 1 ? '/' : segments[segments.length - 2].path;
      this.changePath(path);
    },
    async addFiles(event) {
      this.$emit('add-files', event.target.files);
      this.$refs.inputUpload.value = '';
    },
    async mkdir() {
      this.$emit('loading', true);
      const dir = `${this.path}${this.newFolderName}/`;
      console.log('mkdir', dir);

      const ref = fire.firestore().collection('documentations');

      const arrRes = await ref.where('path', '==', dir).get();

      if (!arrRes.empty) {
        this.$emit('loading', false);
        console.log('Ya tiene una carpeta con ese nombre');
        return;
      }

      const arrResParent = await ref.where('path', '==', this.path).get();

      if (arrResParent.empty) {
        this.$emit('loading', false);
        console.log('No se pudo encontrar el nodo padre');
        return;
      }
      const idresp = localStorage.getItem('lrd5qwlr674');
      const nameres = localStorage.getItem('nc4rhtcc976');

      const decryptNameRes = decryptData(nameres);
      const decryptId = decryptData(idresp);

      const documentations = {
        id: uuidv4(),
        parentId: arrResParent.docs[0].data().id,
        createdBy: decryptNameRes,
        idCreatedBy: decryptId,
        type: 'dir',
        path: dir,
        name: this.newFolderName,
        basename: this.newFolderName,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        updateAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        downloadURL: null,
        size: null,
        extension: null,
        private: false,
        access: [decryptId]
      };

      const res = await ref.add(documentations);
      console.log('RES ->', res);

      this.$emit('folder-created', this.newFolderName);
      this.newFolderPopper = false;
      this.newFolderName = '';
      this.$emit('loading', false);
    },
    async createFolder(value) {
      if (value.length <= 3) {
        throw new Error('El nombre debe contener más de 3 caracteres');
      }

      const dir = `${this.path}${this.newFolderName}/`;
      const ref = fire.database().ref('documentations');
      const arrRes = (
        await ref
          .orderByChild('path')
          .equalTo(dir)
          .once('value')
      ).val();

      if (arrRes !== null) {
        throw new Error('Ya existe una carpeta con ese nombre');
      }

      const arrResParent = (
        await ref
          .orderByChild('path')
          .equalTo(this.path)
          .once('value')
      ).val();

      if (arrResParent === null) {
        throw new Error('No se pudo encontrar el nodo padre');
      }

      const resobj = Object.keys(arrResParent);

      const nameres = localStorage.getItem('nc4rhtcc976');
      const decryptNameRes = decryptData(nameres);

      const documentations = {
        id: uuidv4(),
        parentId: arrResParent[resobj[0]].id,
        createdBy: decryptNameRes,
        type: 'dir',
        path: dir,
        name: this.newFolderName,
        basename: this.newFolderName,
        size: null,
        extension: null
      };

      const res = await fire
        .database()
        .ref('documentations')
        .push(documentations);
      console.log('RES ->', res);

      this.$emit('folder-created', this.newFolderName);
      this.newFolderPopper = false;
      this.newFolderName = '';

      return { state: true };
    }
  },
  created() {
    console.log('>>>', this.path);
  }
};
</script>

<style lang="scss" scoped>
/* Storage Menu - alternate appearance
.storage-select-button ::v-deep .v-btn__content {
    flex-wrap: wrap;
    font-size: 11px;

    .v-icon {
        width: 100%;
        font-size: 22px;
    }
}
*/

.storage-select-list .v-list-item--disabled {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;

  .v-icon {
    color: #fff;
  }
}
</style>
