<template>
  <v-row>
    <v-col>
      <file-browser :maxUploadFileSize="5242880" :maxUploadFilesCount="5" />
    </v-col>
  </v-row>
</template>

<script>
import FileBrowser from '@/components4x/file-browser/FileBrowser';

export default {
  name: 'DocumentBrowser',
  components: {
    FileBrowser
    // ActasView
  },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
  filters: {
    convertSizeFiles(size) {
      const bytes = parseInt(size);
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      if (i === 0) return `${bytes} ${sizes[i]}`;
      const res = (bytes / Math.pow(1024, i)).toFixed(2);
      return `(${res} ${sizes[i]})`;
    }
  },
  async mounted() {},
  async created() {}
};
</script>
