class DocumentacionService {
  constructor() {}
  resources = () => ({
    subareasresponsable: {
      uri: `/v1/subareasconresponsables`,
      method: ['get']
    }
  });
}

export default DocumentacionService;
