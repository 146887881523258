<template>
  <v-overlay :absolute="true">
    <v-card flat light class="mx-auto" :loading="loading">
      <v-card-text class="py-3 text-center">
        <div>
          <span class="grey--text">Subiendo a:</span>
          <v-chip color="info" class="mx-1">{{ storage }}</v-chip>
          <v-chip>{{ path }}</v-chip>
        </div>
        <div v-if="maxUploadFilesCount">
          <span class="grey--text">Cantidas maxima de archivos: {{ maxUploadFilesCount }}</span>
        </div>
        <div v-if="maxUploadFileSize">
          <span class="grey--text">Tamaño maximo de archivo: {{ formatBytes(maxUploadFileSize) }}</span>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="listItems.length" class="pa-0 files-list-wrapper">
        <v-list two-line v-if="listItems.length">
          <v-list-item v-for="(file, index) in listItems" :key="index" link>
            <v-list-item-avatar>
              <v-img v-if="file.preview" :src="file.preview"></v-img>
              <v-icon
                v-else
                v-text="icons[file.extension] ? icons[file.extension].icon : 'mdi-file'"
                class="mdi-36px"
                :color="icons[file.extension] ? icons[file.extension].color : 'grey lighten-1'"
              ></v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="file.name"></v-list-item-title>
              <v-list-item-subtitle>{{ formatBytes(file.size) }} - {{ file.type }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="remove(index)">
                <v-icon color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-else class="py-6 text-center">
        <v-btn @click="$refs.inputUpload.click()"> <v-icon left>mdi-plus-circle</v-icon>Añadir archivo</v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-toolbar dense flat>
        <div class="grow"></div>
        <v-btn text @click="cancel" class="mx-1">Cancelar</v-btn>
        <v-btn depressed color="warning" @click="clear" class="mx-1" :disabled="!files">
          <v-icon>mdi-close</v-icon>Limpiar
        </v-btn>
        <v-btn
          :disabled="listItems.length >= maxUploadFilesCount"
          depressed
          color="info"
          @click="$refs.inputUpload.click()"
          class="mx-1"
        >
          <v-icon left>mdi-plus-circle</v-icon>Añadir archivos
          <input v-show="false" ref="inputUpload" type="file" multiple @change="add" />
        </v-btn>
        <v-btn
          v-auth-acl="'gh-doc-expl-uploadfile'"
          depressed
          color="success"
          @click="upload"
          class="ml-1"
          :disabled="!files"
        >
          Subir
          <v-icon right>mdi-upload-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-overlay :value="uploading" :absolute="true" color="white" opacity="0.9">
        <v-progress-linear v-model="progress" height="25" striped rounded reactive>
          <strong>{{ Math.ceil(progress) }}%</strong>
        </v-progress-linear>
        <p class="black--text" style="text-align: end">{{ countUpload }} / {{ files.length }}</p>
      </v-overlay>
    </v-card>
  </v-overlay>
</template>

<script>
import { formatBytes } from './util';
import fire from '@/fire';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { v4 as uuidv4 } from 'uuid';
import { decryptData } from '@/components4x/utils/utils4x';

const imageMimeTypes = ['image/png', 'image/jpeg'];

export default {
  props: {
    path: String,
    storage: String,
    endpoint: Object,
    files: { type: Array, default: () => [] },
    icons: Object,
    maxUploadFilesCount: { type: Number, default: 0 },
    maxUploadFileSize: { type: Number, default: 0 }
  },
  data() {
    return {
      loading: false,
      uploading: false,
      progress: 0,
      countUpload: 0,
      listItems: []
    };
  },
  methods: {
    formatBytes,

    async filesMap(files) {
      let promises = Array.from(files).map((file) => {
        let result = {
          name: file.name,
          type: file.type,
          size: file.size,
          extension: file.name.split('.').pop()
        };
        return new Promise((resolve) => {
          if (!imageMimeTypes.includes(result.type)) {
            return resolve(result);
          }
          var reader = new FileReader();
          reader.onload = function(e) {
            result.preview = e.target.result;
            resolve(result);
          };
          reader.readAsDataURL(file);
        });
      });

      return await Promise.all(promises);
    },

    async add(event) {
      let files = Array.from(event.target.files);
      this.$emit('add-files', files);
      this.$refs.inputUpload.value = '';
    },

    remove(index) {
      this.$emit('remove-file', index);
      this.listItems.splice(index, 1);
    },

    clear() {
      this.$emit('clear-files');
      this.listItems = [];
    },

    cancel() {
      this.$emit('cancel');
    },

    async upload() {
      const basePath = `documentations/`;

      this.uploading = true;
      this.countUpload = 0;

      for (const file of this.files) {
        const name = file.name
          .split('.')
          .slice(0, -1)
          .join('');
        let obj = {
          name: `${name}_${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
          type: file.type,
          size: file.size,
          extension: file.name.split('.').pop()
        };

        let path = '';

        if (
          obj.extension.toLowerCase() === 'doc' ||
          obj.extension.toLowerCase() === 'docx' ||
          obj.extension.toLowerCase() === 'ppt' ||
          obj.extension.toLowerCase() === 'pptx' ||
          obj.extension.toLowerCase() === 'xls' ||
          obj.extension.toLowerCase() === 'xlsx'
        ) {
          path = `${obj.name}.${obj.extension}`;
        } else {
          path = `${basePath}${obj.name}.${obj.extension}`;
        }

        this.uploading = true;

        const res = await this.uploadImageAsPromise(path, file);

        if (res.state) {
          const ref = fire.firestore().collection('documentations');

          const arrResParent = await ref.where('path', '==', this.path).get();

          const idresp = localStorage.getItem('lrd5qwlr674');
          const nameres = localStorage.getItem('nc4rhtcc976');
          const decryptNameRes = decryptData(nameres);
          const decryptId = decryptData(idresp);

          if (!arrResParent.empty) {
            const documentations = {
              id: uuidv4(),
              parentId: arrResParent.docs[0].data().id,
              createdBy: decryptNameRes,
              idCreatedBy: decryptId,
              type: 'file',
              path: `${this.path}${obj.name}.${obj.extension}`,
              name: `${obj.name}`,
              basename: `${obj.name}.${obj.extension}`,
              createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
              updateAt: moment().format('YYYY-MM-DD HH:mm:ss'),
              size: obj.size,
              extension: obj.extension,
              downloadURL: res.data,
              private: false,
              access: [decryptId]
            };

            // eslint-disable-next-line no-unused-vars
            const resPush = await ref.add(documentations);
          }

          this.countUpload++;
        }
      }

      this.uploading = false;
      this.countUpload = 0;
      this.$emit('uploaded');
    },
    uploadImageAsPromise(path, imageFile) {
      return new Promise((resolve, reject) => {
        const storageRef = fire.storage().ref(path);
        const uploadTask = storageRef.put(imageFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progress = percentage;
          },
          (err) => {
            reject({ state: false, data: err });
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              resolve({ state: true, data: downloadURL });
            });
          }
        );
      });
    }
  },
  watch: {
    files: {
      deep: true,
      immediate: true,
      async handler() {
        this.loading = true;
        this.listItems = await this.filesMap(this.files);
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-overlay__content {
  width: 90%;
  max-width: 700px;

  .files-list-wrapper {
    max-height: 250px;
    overflow-y: auto;
  }
}
</style>
