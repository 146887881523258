var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column folders-tree-card",attrs:{"flat":"","tile":"","width":"400","min-height":"380"}},[_c('div',{staticClass:"grow scroll-x"},[_c('v-treeview',{staticClass:"folders-tree tree-file-browser",attrs:{"open":_vm.open,"active":_vm.active,"items":_vm.treeBase,"search":_vm.filter,"load-children":_vm.readFolder,"item-key":"path","item-text":"basename","dense":"","activatable":"","transition":""},on:{"update:active":_vm.activeChanged},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.type === 'dir')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(open ? 'mdi-folder-open' : 'mdi-folder'))]):_c('v-icon',{attrs:{"color":_vm.icons[item.extension.toLowerCase()] ? _vm.icons[item.extension.toLowerCase()].color : _vm.icons['other'].color}},[_vm._v(_vm._s(_vm.icons[item.extension.toLowerCase()] ? _vm.icons[item.extension.toLowerCase()].icon : _vm.icons['other'].icon))])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.basename)+" ")]}}])})],1),_c('v-divider'),_c('v-toolbar',{staticClass:"shrink",attrs:{"dense":"","flat":""}},[_c('v-text-field',{staticClass:"ml-n3",attrs:{"solo":"","flat":"","hide-details":"","label":"Filtrar","prepend-inner-icon":"mdi-filter-outline"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.init}},on),[_c('v-icon',[_vm._v("mdi-collapse-all-outline")])],1)]}}])},[_c('span',[_vm._v("Contraer todo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.openallnodes}},on),[_c('v-icon',[_vm._v("mdi-expand-all-outline")])],1)]}}])},[_c('span',[_vm._v("Expandir todo")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }